import { ShippingType } from 'state/types/shippingType'
import Api, { spoofMode, spoofedUserId } from './api'

export function submitShippingConfirm(formData: ShippingType) {
  console.log({ formData })
  return new Promise<any>((resolve, reject) => {
    Api.post('wwwREST', '/v1/public/signup/checkout', { body: formData })
      .then((res: any) => {
        console.log('response: ', res)
        resolve(res)
      })
      .catch(err => {
        console.log('error: ', err.response)
        reject(err.response)
      })
  })
}

export function submitShippingConfirmForAccount(formData: ShippingType) {
  const url = spoofMode
    ? '/v1/private/admin/account/plan/checkout?userId=' + spoofedUserId
    : '/v1/private/account/plan/checkout'
  return new Promise<any>((resolve, reject) => {
    Api.post('wwwREST', url, { body: formData })
      .then((res: any) => {
        console.log('response: ', res)
        resolve(res)
      })
      .catch(err => {
        console.log('error: ', err.message)
        reject(err.response.data)
      })
  })
}

export function submitShippingConfirmForAccountExisting(formData: ShippingType) {
  const url = spoofMode
    ? '/v1/private/admin/account/plan/checkout?userId=' + spoofedUserId
    : '/v1/private/account/plan/checkout'
  return new Promise<any>((resolve, reject) => {
    Api.put('wwwREST', url, { body: formData })
      .then((res: any) => {
        console.log('response: ', res)
        resolve(res)
      })
      .catch((err: any) => {
        console.log('error: ', err.message)
        if (err.response.status === 400 && !err.response.data) {
          reject(err)
        } else {
          reject(err.response.data)
        }
      })
  })
}

export function submitShippingConfirmForAccountAddons(formData: ShippingType) {
  const url = spoofMode
    ? '/v1/private/admin/account/plan/addons?userId=' + spoofedUserId
    : '/v1/private/account/plan/addons'
  return new Promise<any>((resolve, reject) => {
    Api.put('wwwREST', url, { body: formData })
      .then((res: any) => {
        console.log('response: ', res)
        resolve(res)
      })
      .catch(err => {
        console.log('error: ', err.message)
        reject(err.response.data)
      })
  })
}

export function submitValidatePostcode(postcode: string) {
  return new Promise<any>((resolve, reject) => {
    Api.post('wwwREST', '/v1/public/signup/address', { body: { postcode: postcode } })
      .then((res: any) => {
        console.log('response: ', res)
        resolve(res)
      })
      .catch(err => {
        console.log('error: ', err.response)
        reject(err.response)
      })
  })
}
